import React from "react";
import ProjectCard from "../components/ProjectCard";

function Projects() {
  const desc0 =
    "Enjoy my free web app: PDF Converter. Effortlessly convert PDF files to text using our machine learning service.Streamline document management with ease.";
  const desc1 =
    "Automated Bot: AI-generated content at your fingertips. Seamlessly integrated with Twitter & OpenAI, no browser tools needed. Take control with the ability to add/delete prompts on the fly and let the bot tweet for you on schedule.";
  const desc2 =
    "Introducing DreamifyAI, an AI image generator app crafted with React Native, AWS, and the stable diffusion API. Seamlessly transform photos into captivating masterpieces, explore abstract visualizations, and unleash your creativity. Available on iOS and Android, DreamifyAI offers a user-friendly interface for effortless image manipulation. ";
  const desc3 =
    " Transform Your Thoughts into Organized Notes with Ease. Just Hit Record, Speak to the Chatbot, Upload, and from there We'll process it and  return back your thoughts summarized and structured.";
  const desc4 =
    "Discover my personal portfolio website, built with AWS CloudFront, SES, React, and a custom UI. Explore my projects and experience showcased in a seamless and captivating manner";
  const desc5 =
    "Immerse your videos with custom captions, Boost engagement, maximize viewership and retain your audience.";
  return (
    <div className="work-container">
      <ul>
        <a
          href="https://www.bytecap.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <li className="projectcard-li">
            <ProjectCard
              image={"bytecapdash.png"}
              title={"ByteCap"}
              description={desc5}
              link={true}
              url={"https://www.bytecap.io/"}
              tech={["Next.js", "AWS", "Tailwind"]}
            />
          </li>
        </a>
        <a href="https://senryu.ai" target="_blank" rel="noopener noreferrer">
          <li className="projectcard-li">
            <ProjectCard
              image={"soundsenseimg.png"}
              title={"Senryu AI"}
              description={desc3}
              link={true}
              url={"https://senryu.ai"}
              tech={["AWS", "React", "OpenAI"]}
            />
          </li>
        </a>
        <a
          href="http://fileconverterapp.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <li className="projectcard-li">
            <ProjectCard
              link={true}
              image={"fileconverterapp.png"}
              title={"File Converter App"}
              description={desc0}
              url={"http://fileconverterapp.com/"}
              tech={["AWS", "React"]}
            />
          </li>
        </a>
        <li className="projectcard-li">
          <ProjectCard
            link={false}
            image={"AiTwitterImage.png"}
            title={"AI Twitter Companion"}
            description={desc1}
            tech={["OpenAI API", "Twitter API", "AWS"]}
          />
        </li>
        <li className="projectcard-li">
          <ProjectCard
            link={false}
            image={"dreamifyImg.png"}
            title={"DreamifyAI"}
            description={desc2}
            tech={["Stable Diffusion API", "AWS", "React Native"]}
          />
        </li>

        <li className="projectcard-li">
          <ProjectCard
            link={false}
            image={"portfolioImg.png"}
            title={"Personal Portfolio"}
            description={desc4}
            tech={["AWS", "React"]}
          />
        </li>
      </ul>
    </div>
  );
}

export default Projects;
