import React from "react";
import "./SkillsPage.css";
const TechnicalSkillsList = () => {
  const webSkills = [
    { skill: "JavaScript", experience: "7 years" },
    { skill: "React", experience: "7 years" },
    { skill: "Next.js", experience: "2 years" },
    { skill: "HTML", experience: "2 years" },
    { skill: "CSS", experience: "7 years" },
    { skill: "NPM", experience: "7 years" },
    { skill: "TypeScript", experience: "2 years" },
    { skill: "TailWind", experience: "2 years" },
    { skill: "Cucumber.js", experience: "2 year" },

    // Add more skills as needed
  ];
  const serverSkills = [
    { skill: "Node.js", experience: "5 years" },
    { skill: "Express.js", experience: "3 years" },
    { skill: "AWS", experience: "3 years" },
    { skill: "Firebase", experience: "3 years" },
    { skill: "Azure", experience: "1 year" },
    { skill: "infrastructure of code", experience: "3 years" },
    { skill: "Serverless Framework", experience: "2 years" },
    { skill: "Docker", experience: "2 year" },
    { skill: "Terraform", experience: "1 year" },
  ];
  const miscSkills = [
    { skill: "Git", experience: "7 years" },
    { skill: "GitHub", experience: "7 years" },
    { skill: "Figma", experience: "5 years" },
    { skill: "NPM", experience: "7 years" },
    { skill: "OpenAI", experience: "1 year" },
  ];

  return (
    <div>
      <div className="skills-page" style={{ padding: "20px" }}>
        <h2 style={{ textAlign: "left", color: "white" }}>WEB</h2>
        <table className="skills-table" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Skill</th>
              <th>Experience</th>
            </tr>
          </thead>
          <tbody>
            {webSkills.map((skill, index) => (
              <tr key={index}>
                <td>{skill.skill}</td>
                <td>{skill.experience}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="skills-page" style={{ padding: "20px" }}>
        <h2 style={{ textAlign: "left", color: "white" }}>Server</h2>
        <table className="skills-table" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Skill</th>
              <th>Experience</th>
            </tr>
          </thead>
          <tbody>
            {serverSkills.map((skill, index) => (
              <tr key={index}>
                <td>{skill.skill}</td>
                <td>{skill.experience}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="skills-page" style={{ padding: "20px" }}>
        <h2 style={{ textAlign: "left", color: "white" }}>Tools & Platforms</h2>
        <table className="skills-table" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Skill</th>
              <th>Experience</th>
            </tr>
          </thead>
          <tbody>
            {miscSkills.map((skill, index) => (
              <tr key={index}>
                <td>{skill.skill}</td>
                <td>{skill.experience}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TechnicalSkillsList;
