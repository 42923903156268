import React from "react";
import FooterLinks from "./FooterLinks";

function LeftContainer({ handleListItemClick, activeSection, Route }) {
  return (
    <div className="left-container">
      <div className="container-content">
        <div id="landing-header">
          <h1>Ali Mansour</h1>
          <h2 className="main-subheader">Software Engineer</h2>

          <div>
            <div style={{ paddingTop: "1vh" }}>
              <p id="subTitle">
                As a software engineer specializing in React and Node.js, I
                prioritize building accessible and inclusive products and
                digital experiences.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ul className="nav-container">
        <li
          className={activeSection === "about" ? "active" : ""}
          onClick={() => handleListItemClick("about")}
        >
          ABOUT
        </li>
        <li
          className={activeSection === "projects" ? "active" : ""}
          onClick={() => handleListItemClick("projects")}
        >
          PROJECTS
        </li>
        <li
          className={activeSection === "contact" ? "active" : ""}
          onClick={() => handleListItemClick("contact")}
        >
          <span>CONTACT</span>
        </li>
        {/* <li className="blog-header-link">
          <a
            className="blog-a-container"
            href="https://tech-hangout.beehiiv.com/"
            target="_blank"
            rel="noopener"
          >
            <span style={{ marginRight: 2 }}>Newsletter</span>
            <span className="material-symbols-outlined blog-icon">
              move_group
            </span>
          </a>
        </li> */}
      </ul>
      <div className="footer-links-left-container">
        <FooterLinks />
      </div>
    </div>
  );
}

export default LeftContainer;
