import React from "react";
const BlogHeader = () => {
  const handleHomeClick = () => {
    window.location.href = "/";
  };
  return (
    <header style={styles.header}>
      <div style={styles.homeButton} onClick={handleHomeClick}>
        Home
      </div>
      {/* <div style={styles.navButtons}>
        <div style={styles.navButton}>
          <Link to="/about">About</Link>
        </div>
        <div style={styles.navButton}>
          <Link to="/contact">Contact</Link>
        </div>
      </div> */}
    </header>
  );
};

export default BlogHeader;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100vw",
    height: "100vh",
    backgroundColor: "#09111e",
    fontFamily: "Khula, monospace",
    color: "white",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "20px 20px 100px 20px",
  },
  homeButton: {
    fontSize: "16px",
    fontWeight: "bold",
    cursor: "pointer",
  },
  navButtons: {
    display: "flex",
    gap: "10px",
  },
  navButton: {
    fontSize: "14px",
    cursor: "pointer",
  },
  contentContainer: {
    maxWidth: "500px",
    width: "100%",
    padding: "40px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  title: {
    fontSize: "36px",
    fontWeight: "bold",
    marginBottom: "10px",
    color: "#333",
  },
  date: {
    fontSize: "14px",
    color: "#888",
    marginBottom: "20px",
  },
  content: {
    fontSize: "18px",
    lineHeight: "30px",
    color: "#333",
  },
};
