import React from "react";

function AlertBox({ text }) {
  return (
    <div id="alert-box">
      <p>{text}</p>
    </div>
  );
}

export default AlertBox;
