import React from "react";
import linkedin from "../images/linkedin.png";
import twitter from "../images/twitter.png";
import github from "../images/github.png";
import medium from "../images/medium.png";
// import beehivlogo from "../images/beehivlogo.png";

function FooterLinks() {
  return (
    <div className="footer-links">
      <ul>
        <li className="footer-link">
          <a href="https://twitter.com/alzz30" target="_blank" rel="noopener">
            <div className="vert-links-overlay">
              <img style={{ width: "25px" }} src={twitter} alt={"Twitter"} />
            </div>
          </a>
        </li>
        <li className="footer-link">
          <a href="https://github.com/alzz0" target="_blank" rel="noopener">
            <div className="vert-links-overlay">
              <img style={{ width: "25px" }} src={github} alt={"GitHub"} />
            </div>
          </a>
        </li>
        <li className="footer-link">
          <a
            href="https://www.linkedin.com/in/alimansour28/"
            target="_blank"
            rel="noopener"
          >
            <div className="vert-links-overlay">
              <img style={{ width: "25px" }} src={linkedin} alt={"LinkedIn"} />
            </div>
          </a>
        </li>
        {/* <li className="footer-link">
          <a
            href="https://tech-hangout.beehiiv.com/"
            target="_blank"
            rel="noopener"
          >
            <div className="vert-links-overlay">
              <img style={{ width: "30px" }} src={beehivlogo} alt={"Medium"} />
            </div>
          </a>
        </li> */}
      </ul>
    </div>
  );
}

export default FooterLinks;
