import React from "react";

function AboutCard({ icon, title, description, tech }) {
  const handleMouseEnter = (e) => {
    const cards = document.querySelectorAll(".about-card");
    cards.forEach((card) => {
      if (card !== e.currentTarget) {
        card.classList.add("opacity-reduced");
      }
    });
  };

  const handleMouseLeave = () => {
    const cards = document.querySelectorAll(".about-card");
    cards.forEach((card) => {
      card.classList.remove("opacity-reduced");
    });
  };

  return (
    <div
      className="about-card"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span className="material-symbols-outlined about-icon-container">
        <p>{icon}</p>
      </span>
      <div className="desc-container">
        <p className="p-title">{title}</p>
        <p>{description}</p>
        <ul className="tag-list">
          {tech &&
            tech.map((elm) => (
              <li key={elm} className="tag purple">
                {elm}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default AboutCard;
