import React from "react";
import AboutCard from "../components/AboutCard";
import { Link } from "react-router-dom";
function About() {
  return (
    <div className="about-container">
      <p>
        Proficient in JavaScript and its vast ecosystem as well as a deep love
        for cloud technologies like AWS.
      </p>
      <p>
        With a solid background in developing platforms for web, mobile, and
        connected TV (CTV), I have contributed to a range of successful startup
        ventures and agencies. Some notable companies in my portfolio are
        McCain, NetCoins, and KidoodleTV.
      </p>
      {/* <p>
        As of late, my focus has taken a turn towards constructing cloud
        services leveraging the power of AWS and Azure. Alongside that, I've
        been immersed in experimenting with the OpenAI API, exploring its vast
        potential.
      </p> */}
      <div className="about-cards">
        {/* <Link to="/skills"> */}
        <ul>
          <li>
            <AboutCard
              icon={"computer"}
              title={"Web"}
              description={
                "Experienced Front-End developer specializing in React and JavaScript. I create engaging and interactive user experiences, leveraging modern web development practices to build visually stunning and highly functional websites."
              }
              tech={[
                "React",
                "JavaScript",
                "Next.js",
                "TypeScript",
                "CSS",
                "Tailwind",
              ]}
            />
          </li>
          <li>
            <AboutCard
              icon={"terminal"}
              title={"Server"}
              description={
                "Proficient in backend development with Node.JS and AWS and Azure cloud platforms. I architect and build scalable, secure, and efficient server-side solutions that handle heavy workloads and ensure high availability and security."
              }
              tech={["NodeJS", "AWS", "Azure", "IAC", "Firebase"]}
            />
          </li>
          <li>
            <AboutCard
              icon={"phone_iphone"}
              title={"Mobile"}
              description={
                "Skilled in developing cross-platform mobile applications using React Native. I deliver native-like experiences for iOS and Android, ensuring smooth performance, seamless navigation, and a polished user interface."
              }
              tech={["React Native", "Xcode"]}
            />
          </li>
        </ul>
        {/* </Link> */}
      </div>
    </div>
  );
}

export default About;
