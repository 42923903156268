import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
} from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import BlogPost from "./pages/blog/BlogPost";
import TechnicalSkillsList from "./pages/technicalSkillsList/TechnicalSkillsList";
import LeftContainer from "./components/LeftContainer";
import Projects from "./pages/Projects";
import About from "./pages/About";
import Contact from "./pages/Contact";
import SkillsPage from "./pages/skills/SkillsPage";
import "./css/App.css";
import FooterLinks from "./components/FooterLinks";

function App() {
  const [activeSection, setActiveSection] = useState("about");
  const [scrollDirection, setScrollDirection] = useState("down");
  const rightSideRef = useRef(null);
  const observerRef = useRef(null);
  const scrollTimeoutRef = useRef(null);
  const sectionPositionsRef = useRef([]);

  const handleListItemClick = (sectionId) => {
    setActiveSection(sectionId);
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScroll = useCallback(() => {
    clearTimeout(scrollTimeoutRef.current);
    scrollTimeoutRef.current = setTimeout(() => {
      const { scrollTop } = rightSideRef.current;
      const scrollDirection =
        scrollTop > sectionPositionsRef.current ? "down" : "up";

      const { clientHeight } = rightSideRef.current;
      const sections = document.getElementsByClassName("section");

      let foundSectionId = null;

      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];
        const { offsetTop, offsetHeight, id } = section;

        const adjustedOffsetHeight = offsetHeight + clientHeight * 0.1;

        if (
          scrollDirection === "down" &&
          scrollTop >= offsetTop - clientHeight * 0.5 &&
          scrollTop < offsetTop + adjustedOffsetHeight - clientHeight * 0.5
        ) {
          foundSectionId = id;
          break;
        } else if (
          scrollDirection === "up" &&
          scrollTop >= offsetTop - clientHeight * 0.5 &&
          scrollTop < offsetTop + adjustedOffsetHeight
        ) {
          foundSectionId = id;
          break;
        }
      }

      if (foundSectionId) {
        setActiveSection(foundSectionId);
      }

      setScrollDirection(scrollDirection);

      const sectionPositions = Array.from(
        document.getElementsByClassName("section")
      ).map((section) => ({
        id: section.id,
        position: section.offsetTop,
      }));
      sectionPositionsRef.current = sectionPositions.map(
        (section) => section.position
      );
    }, 200); // Adjust the delay (in milliseconds) based on your requirements
  }, []);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.5 }
    );

    const sections = document.getElementsByClassName("section");
    Array.from(sections).forEach((section) => {
      observerRef.current.observe(section);
    });

    const rightSideElement = rightSideRef.current;

    if (rightSideElement) {
      rightSideElement.addEventListener("scroll", handleScroll);

      return () => {
        if (observerRef.current) {
          observerRef.current.disconnect();
        }
        rightSideElement.removeEventListener("scroll", handleScroll);
        clearTimeout(scrollTimeoutRef.current);
      };
    }
  }, [handleScroll]);

  useEffect(() => {
    const sectionPositions = Array.from(
      document.getElementsByClassName("section")
    ).map((section) => ({
      id: section.id,
      position: section.offsetTop,
    }));
    sectionPositionsRef.current = sectionPositions;
  }, []);

  return (
    <Router>
      <div className="app">
        <Switch>
          <Route exact path="/blog" component={BlogPost} />
          <Route exact path="/skills" component={TechnicalSkillsList} />
          <Route path="/">
            <Fragment>
              <div className="left-side">
                <LeftContainer
                  handleListItemClick={handleListItemClick}
                  activeSection={activeSection}
                />
              </div>
              <div className="right-side" ref={rightSideRef}>
                <div className="section" id="about">
                  <About />
                </div>
                <div className="section" id="projects">
                  <Projects />
                </div>
                <div className="section" id="contact">
                  <div className="footer-links-contact">
                    <FooterLinks />
                  </div>
                  <Contact />
                </div>
              </div>
            </Fragment>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
