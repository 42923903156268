import React, { useState } from "react";
import rightArrow from "../images/rightArrow.png";
import "../css/Contact.css";
import axios from "axios";
import AlertBox from "../utils/AlertBox";
function ContactForm() {
  const [messageData, setMessageData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleChange(e) {
    setMessageData({ ...messageData, [e.target.id]: e.target.value });
  }

  function handleAlert() {
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 8000);
  }
  async function handleSubmit(e) {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    const url =
      "https://6chpzsa931.execute-api.us-east-1.amazonaws.com/dev/message/send";
    const { name, email, message } = messageData;
    const results = await axios.post(url, { name, email, message });
    setLoading(false);

    if (results.status === 200) {
      setMessageData({
        name: "",
        email: "",
        message: "",
      });
    }
    handleAlert();
  }

  return (
    <div id="form-container">
      {alert && <AlertBox text="Message successfully delivered!" />}
      <h1 id="form-header">contact</h1>
      <form id="contact-form" onSubmit={handleSubmit}>
        <div id="contact-name">
          <input
            type="text"
            value={messageData.name}
            required
            id="name"
            placeholder="Name"
            onChange={handleChange}
          />
        </div>
        <div id="contact-email">
          <input
            type="email"
            value={messageData.email}
            required
            id="email"
            placeholder="Email"
            onChange={handleChange}
          />
        </div>
        <div id="contact-message">
          {" "}
          <textarea
            id="message"
            value={messageData.message}
            required
            placeholder="Message"
            onChange={handleChange}
          ></textarea>
        </div>
        <button id="contact-submit" type="submit">
          <p id="btn-text">{loading ? "SENDING..." : "SUBMIT"}</p>{" "}
          <span id="btn-arrow">
            <img id="right-arrow-image" src={rightArrow} alt="" />
          </span>
        </button>
      </form>
    </div>
  );
}

export default ContactForm;
