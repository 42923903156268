import React, { useState, useEffect } from "react";
import ContactForm from "../components/ContactForm.js";

function Contact() {
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function toggle(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }
  return (
    <div id="contact-section">
      <div className="contact-section-header">
        <h5>
          Got an exciting idea or <br /> just want to say hello?
        </h5>
        {/* <h5>or just want to say hello?</h5> */}
        <p>Let's get in touch!</p>
        <img src="clipartarrow.png" width="30" alt="down arrow" />
      </div>
      <ContactForm />
    </div>
  );
}

export default Contact;
