import React from "react";

function ProjectCard({ image, title, description, tech, url = "#", link }) {
  const handleMouseEnter = (e) => {
    const cards = document.querySelectorAll(".card-container");
    cards.forEach((card) => {
      if (card !== e.currentTarget) {
        card.classList.add("opacity-reduced");
      }
    });
  };

  const handleMouseLeave = () => {
    const cards = document.querySelectorAll(".card-container");
    cards.forEach((card) => {
      card.classList.remove("opacity-reduced");
    });
  };

  return (
    // <a href={url} target="_blank" rel="noopener">
    <div
      className="card-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="project-image">
        <img src={image} alt="" width={140} height={70} />
      </div>

      <div className="desc-container">
        <div style={{ display: "flex" }}>
          <p className="p-title" style={{ marginRight: 5 }}>
            {title}
          </p>

          {url !== "#" && (
            <div className="projects-link-icon">
              <span className="material-symbols-outlined blog-icon">
                move_group
              </span>
            </div>
          )}
        </div>
        {/* <span className="material-symbols-outlined blog-icon">move_group</span> */}
        <p>{description}</p>
        <ul className="tag-list">
          {tech &&
            tech.map((elm) => (
              <li key={elm} className="tag purple">
                {elm}
              </li>
            ))}
        </ul>
      </div>
    </div>
    // </a>
  );
}

export default ProjectCard;
